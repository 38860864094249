.ui.modal .save-search-header {
  background-color: rgb(0, 107, 181);
  color: rgb(255, 255, 255, 0.85);
}

.search-terms-div {
  padding-bottom: 1em;
}

.save-search-actions {
  background: #f9fafb;
  padding: 0rem 0rem 0rem 0rem;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  text-align: right;
}

.button.save-search-button {
  margin-left: 0.75em;
}
