.ui.card .content.SESS {
  border-left-style: solid;
  border-left-width: 0.8em;
  border-left-color: rgba(255, 0, 0, 0.6);
  background-color: rgba(211, 211, 211, 0.5);
}

.ui.card .content.SAFT {
  border-left-style: solid;
  border-left-width: 0.8em;
  border-left-color: rgba(0, 255, 0, 0.6);
  background-color: rgba(211, 211, 211, 0.5);
}

.ui.card .content.SCRE {
  border-left-style: solid;
  border-left-width: 0.8em;
  border-left-color: rgba(0, 0, 255, 0.6);
  background-color: rgba(211, 211, 211, 0.5);
}

.ui.card .content.PT {
  border-left-style: solid;
  border-left-width: 0.8em;
  border-left-color: rgba(255, 251, 0, 0.6);
  background-color: rgba(211, 211, 211, 0.5);
}

.ui.card .content div.inline {
  float: left;
}
