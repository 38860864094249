.RegistrationPage {
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.paper {
  max-width: 450px;
  padding: 20px;
  margin: 0 auto;
}
.button {
  margin-top: 10px;
  margin-bottom: 10px;
}

.container {
  margin: 0 auto;
}
