.ui.modal .STG_MODEL_CPARS.add-asset-header {
  background-color: cyan;
}

.ui.modal .STG_MODEL_GWAC.add-asset-header {
  background-color: rgba(0, 212, 53, 0.6);
}

.add-asset-actions {
  background: #f9fafb;
  padding: 0rem 0rem 0rem 0rem;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  text-align: right;
}

.button.add-asset-button {
  margin-left: 0.75em;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: inherit;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  min-height: 200px;
}

.dropzone-active {
  border-width: 1px;
  border-radius: 1px;
  border-color: #eeeeee;
  border-style: solid;
  min-height: 200px;
  max-height: 200px;
  overflow-y: auto;
  width: inherit;
}

.ui.list.file-list {
  margin-top: 10;
}

.remove-file {
  padding: inherit;
}

.table-input {
  width: 100%;
}

.table-values {
  padding-left: 0.5em;
  padding-top: 0.5em;
}

.asset-tags > .react-tagsinput {
  background-color: #fff;
  overflow: hidden;
  padding-left: 0px;
  padding-top: 0px;
  border-radius: 0.28571429rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
}

.asset-tags .react-tagsinput-tag {
  margin-bottom: 0em;
  margin-right: 0em;
  margin-left: 0.2em;
  margin-top: 0.15em;
  border-color: #85b7d9;
  background-color: unset;
  border-radius: 0.28571429rem;
  color: unset;
}

.asset-tags .react-tagsinput--focused {
  color: rgba(0, 0, 0, 0.95);
  border-color: #85b7d9;
  border-radius: 0.28571429rem;
  background: #fff;
  box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.35) inset;
}

.asset-tags input[type='text'] {
  width: 30% !important;
  border: unset !important;
  font-size: 0.98em !important;
  padding-top: 0.67857143em !important;
  padding-bottom: 0.5em !important;
  padding-left: 1em !important;
  padding-right: 1em !important;
}

.select-file {
  float: right;
}

.file-icon {
  padding-top: 5px;
}
