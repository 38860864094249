#content-grid {
  padding-top: 7em;
}

#content-grid .left.column {
  width: 300px !important;
}

/*
    https://philipwalton.github.io/solved-by-flexbox/demos/sticky-footer/
    .site and #content-wrapper: flex: 1 are used to make our footer
    sticky to the bottom of the site content
*/
.site {
  /* display: flex; */
  /* min-height: 100vh; */
  padding: 30px;
  flex-direction: column;
}

#content-wrapper {
  padding-top: 4.6em;
  display: flex;
  flex: 1;
}

#content {
  padding: 1em;
  flex-grow: 1;
}

.ui.segment.disclosure {
  background-color: rgb(255, 242, 204);
}

.extra.content .checkbox {
  float: right;
}

/* WIP.JS CSS */
/* Position text in the middle */
.middle {
  position: relative;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  text-align: center;
  /* background-color: lightblue; */
}
