.ui.labeled.loading-ids.button > .icon {
  background-color: unset;
}

.ui.active.selection.button:hover {
  background-color: unset;
}

.ui.selection.button {
  font-weight: unset;
}

.saved-search-term-label {
  padding-bottom: 1em;
}

.skip-filter-bar {
  height: 30px;
  left: 50%;

  position: absolute;
  transform: translateY(-1200%);
  transition: transform 0.3s;
}

.skip-filter-bar:focus {
  transform: translateY(0%);
  padding: 3px;
  background-color: #ffffff;
}
