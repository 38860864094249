.ui.menu.action-bar {
  margin-top: -1em;
}

.ui.menu.action-bar .item {
  padding-left: 0.5em;
  padding-right: 0.5em;
  margin-left: 0;
  margin-right: 0;
  min-width: 0;
}

.ui.menu.action-bar > :first-child {
  padding-left: 0;
}

.ui.menu.action-bar .separator {
  display: inline-block;
  width: 1px;
  height: 1.5rem;
  background-color: #dcdcdc;
}
