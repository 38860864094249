.details-view .header.SESS-modal-header {
  padding-top: 0.2em;
  background-color: rgba(255, 0, 0, 0.6);
}

.details-view .header.SAFT-modal-header {
  padding-top: 0.2em;
  background-color: rgba(0, 255, 0, 0.6);
}

.details-view .header.SCRE-modal-header {
  padding-top: 0.2em;
  background-color: rgba(0, 0, 255, 0.6);
}

.ui.modal .STG_MODEL_GWAC.edit-asset-header {
  background-color: rgba(0, 212, 53, 0.6);
}

.iconButton {
  padding: 10;
}

.input {
  flex: 1;
}

.paper {
  padding: 2px 4px;
  display: flex;
  align-items: center;
  /* width: 400; */
}

.SESS {
  padding-top: 0.2em;
  background-color: rgba(255, 0, 0, 0.6);
}

.SAFT {
  padding-top: 0.2em;
  background-color: rgba(0, 255, 0, 0.6);
}

.SCRE {
  padding-top: 0.2em;
  background-color: rgba(0, 0, 255, 0.6);
}

.PT {
  padding-top: 0.2em;
  background-color: rgba(255, 251, 0, 0.6);
}

.dialogTitleText {
  color: white !important;
  font-size: 1.5rem !important;
  font-family: poppins-semibold, poppins, sans-serif;
  font-weight: 500;
  line-height: 1.6;
}

.blueLink {
  cursor: pointer;
  color: blue;
}

.bluelink:hover {
  cursor: pointer;
  text-decoration: underline;
}
