.results-count {
  margin: auto;
  border: '1pt solid red';
  font-weight: unset;
}

.results-count.value {
  align-items: center;
  display: flex;
}
