.ui.menu .item > i.icon.pdf {
  color: red;
}

.tab embed[type='application/pdf'] {
  height: 600px;
  width: 100%;
}

/* loader classes needed to address known issue when loader is in a modal
https://github.com/Semantic-Org/Semantic-UI-React/issues/3133 */
.ui.dimmer .ui.loader:before {
  border-color: rgba(0, 0, 0, 0.1);
}

.ui.dimmer .ui.loader:after {
  border-color: #767676 transparent transparent;
}

.ui.dimmer .ui.loader > div {
  color: black;
}
