.ui.header.filter-header {
  margin-top: 0;
}

#filters {
  padding: 1em;
  padding-bottom: 40px;
  width: 275px;
  flex: 0 0 auto;
}

.filter-content {
  padding-left: 2em;
}

.ui.label.remove-label,
.ui.label.remove-label:hover {
  background-color: #28badbad;
  border-color: #28badbad;
}

.ui.label.undefined-filter-label {
  font-style: italic;
}

.ui.inverted.dimmer.facet-dimmer {
  z-index: 100;
}

.skip-filter {
  height: 30px;
  left: 25%;
  position: absolute;

  transform: translateY(-1000%);
  transition: transform 0.3s;
}

.skip-filter:focus {
  transform: translateY(0%);
  padding: 3px;
  background-color: #ffffff;
}
