.ui.button.show_more_or_less {
  background: white;
  color: #4183c4;
  border: none;
  border-radius: 0;
  padding-top: 0.1em;
  padding-right: 0.1em;
  padding-bottom: 0.1em;
  padding-left: 0.1em;
}

.ui.button.show_more_or_less:hover {
  background-color: lightgray;
}
