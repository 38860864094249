.NominateArtifact {
  width: 100%;
}
.dialogTitle {
  background-color: #004b71;
  color: white !important;
}
.dialogTitleText {
  color: white !important;
  font-size: 1.25rem;
  font-family: poppins-semibold, poppins, sans-serif;
  font-weight: 500;
  line-height: 1.6;
}
